<template>
  <b-card
    v-if="data"
    text-variant="center"
    class="card card-congratulations bg-gradient-warning"
  >
    <!-- images -->
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <!--/ images -->
    <b-avatar
      variant="primary"
      :src="data.avatar"
      :text="avatarText(data.user.name)"
      size="70"
      class="shadow mb-1"
    />
    <h1 class="mb-1 mt-50 text-white">{{ pascalCase(data.user.name) }}</h1>
    <b-card-text class="m-auto w-75 text-white">
      {{ data.award.title }}
    </b-card-text>
  </b-card>
</template>

<script>
import { BCard, BImg, BAvatar, BCardText } from 'bootstrap-vue'
import { avatarText, pascalCase } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    avatarText,
    pascalCase,
  },
}
</script>

<style>
.card-congratulations {
  background-image: linear-gradient(47deg, #ff9f43, #ffb976);
}
</style>
